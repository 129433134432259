<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Unicons Icon</h4>
          </template>
          <template v-slot:body>
            <p>Use Class <code>&lt;i class="ion-archive"&gt;&lt;/i&gt;</code></p>
            <b-row class="mt-3">
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-archive"></i>Archive</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-reply"></i>Reply</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-reply-all"></i>Reply All</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-forward"></i>Forward</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-play"></i>Play</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-volume-high"></i>Volume High</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-pricetags"></i>Pricetags</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-pause"></i>Pause</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-briefcase"></i>Briefcase</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-medkit"></i>Medkit</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-at"></i>At</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-pound"></i>Pound</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-asterisk"></i>Asterisk</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-alert"></i>Alert</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-alert-circled"></i>Alert Circled</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-refresh"></i>Refresh</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-loop"></i>Loop</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-shuffle"></i>Shuffle</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-home"></i>Home</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-search"></i>Search</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-flag"></i>Flag</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-heart"></i>Heart</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-heart-broken"></i>Broken</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-gear-a"></i>Gear</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-gear-b"></i>Gear bold</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-toggle-filled"></i>Toggle</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-trash-b"></i>Trash fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-hammer"></i>Hammer</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-wrench"></i>Wrench</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-information-circled"></i>Information</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-help-circled"></i>Help</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-plus-circled"></i>Plush</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-close-circled"></i>Close</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-email-unread"></i>Email</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-upload"></i>Upload</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-soup-can"></i>Database</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-thumbsup"></i>Thumbsup</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-thumbsdown"></i>Thumbsdown</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-pricetag"></i>Pricetag</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ion-bowtie"></i>Bowtie</b-link>
              </b-col>
              <b-col sm="12" class="text-center mt-3">
                <b-link href="https://www.unicon.net/" target="blank" class="btn btn-primary">View All Icon</b-link>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
export default {
  name: 'IconUnicons',
  mounted () {
    core.index()
  }
}
</script>
